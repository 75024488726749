import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Roles } from '@core/_enums/roles.enum';
import {
    TypesMenuButton,
    TypesMenuIcon,
} from '@core/_enums/types-sidemenu.enum';
import { User } from '@core/models/user.model';
import { ArbitratorRoutes } from '@core/routes/arbitrator.routes';
import { BranchManagerRoutes } from '@core/routes/branch-manager.routes';
import { CaseManagerUserRoutes } from '@core/routes/case-manager-user.routes';
import { ClaimantRoutes } from '@core/routes/claimant.routes';
import { LegalRepresentativeRoutes } from '@core/routes/legal-representative.routes';
import { RespondentRoutes } from '@core/routes/respondent.routes';
import { SalesRepresentativeRoutes } from '@core/routes/sales-representative.routes';
import { SuperAdminRoutes } from '@core/routes/super-admin.routes';
import { ViewOnlyUserRoutes } from '@core/routes/view-only-user.routes';
import { GeneralSettingsService } from '@core/services/data-access/general-settings.service';
import { UserStoreService } from '@core/services/store/user-store.service';
import { MenuItem } from 'primeng/api';
import { Subject, map, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu',
    standalone: false,
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {
    SideMenuRoutes!: MenuItem[];

    private readonly onDestroyView$ = new Subject<void>();
    private userStoreService = inject(UserStoreService);
    private generalSettingsService = inject(GeneralSettingsService);

    constructor() {
        console.log('🚀 ~ AppMenuComponent ~ constructor ~ constructor:');
        this.userStoreService.userStream
            .pipe(
                takeUntil(this.onDestroyView$),
                map((userState) => {
                    console.log(
                        '🚀 ~ AppMenuComponent ~ constructor ~ userState:',
                        userState
                    );

                    if (userState) {
                        console.log(
                            `🚀 LOADED ${
                                userState.associatedTo
                                    ? 'With Associated To'
                                    : 'Whithout Associated To'
                            }`
                        );
                        if (!this.SideMenuRoutes)
                            this.loadCurrentRoutesForUser(
                                userState.roles.at(0) ?? Roles.Undefined
                            );

                        // SIDEMENU ROUTES LOADED VALIDATION FOR ROLE LEGAL REPRESENTATIVE & ASSOCIATED WITH CLAIMANT
                        // EXTRA VALIDATION FOR AVOID DUPLICATION FILE A NEW DISPUTE BUTTON
                        if (userState.roles.at(0) === Roles.LegalRepresentative)
                            this.processValidationsRepresentativeAsociatedClaimant(
                                userState
                            );
                    } else if (this.SideMenuRoutes) {
                        this.SideMenuRoutes = [];
                    }
                })
            )
            .subscribe();
    }

    ngOnInit() {}

    ngOnDestroy(): void {
        console.log('🚀 ~ AppMenuComponent ~ ngOnDestroy ~ ngOnDestroy:');
        this.onDestroyView$.next();
        this.onDestroyView$.complete();
    }

    private loadCurrentRoutesForUser(actualRoles: Roles) {
        switch (actualRoles) {
            case Roles.SuperAdmin:
                this.SideMenuRoutes = new SuperAdminRoutes().Routes;
                break;

            case Roles.Admin:
                this.SideMenuRoutes = new SuperAdminRoutes().Routes;
                break;

            case Roles.Claimant:
                this.SideMenuRoutes = new ClaimantRoutes().Routes;
                break;

            case Roles.Respondent:
                this.SideMenuRoutes = new RespondentRoutes().Routes;
                break;

            case Roles.SalesRepresentative:
                this.SideMenuRoutes = new SalesRepresentativeRoutes().Routes;
                break;

            case Roles.Arbitrator:
                this.SideMenuRoutes = new ArbitratorRoutes().Routes;
                break;

            case Roles.LegalRepresentative:
                this.SideMenuRoutes = new LegalRepresentativeRoutes().Routes;
                break;

            case Roles.BranchManager:
                this.SideMenuRoutes = new BranchManagerRoutes().Routes;
                break;

            case Roles.CaseManagerUser:
                this.SideMenuRoutes = new CaseManagerUserRoutes().Routes;
                break;

            case Roles.ViewOnlyUser:
                this.SideMenuRoutes = new ViewOnlyUserRoutes().Routes;
                break;

            default:
                break;
        }
    }

    private processValidationsRepresentativeAsociatedClaimant(userState: User) {
        // SIDEMENU ROUTES LOADED VALIDATION FOR ROLE LEGAL REPRESENTATIVE & ASSOCIATED WITH CLAIMANT || BRANCH-MANAGER
        // EXTRA VALIDATION FOR AVOID DUPLICATION FILE A NEW DISPUTE BUTTOn
        if (
            userState.associatedTo &&
            (userState.associatedTo.roles.at(0)?.name === Roles.Claimant ||
                userState.associatedTo.roles.at(0)?.name ===
                    Roles.BranchManager) &&
            !this.SideMenuRoutes.find(
                (value) => value.id === TypesMenuButton.NewCase
            )
        ) {
            this.SideMenuRoutes.splice(1, 0, {
                id: TypesMenuIcon.Custom,
                label: 'Calendar',
                icon: 'assets/icons/sidemenu/calendar.svg',
                routerLink: '/calendar',
            });

            // NEW CASE INTAKE AND ANALYSIS
            this.SideMenuRoutes.splice(2, 0, {
                id: TypesMenuIcon.Custom,
                label: 'Matter Intake and Analysis',
                icon: 'assets/icons/sidemenu/case-intake-and-analysis.svg',
                routerLink: '/case-analysis',
            });

            this.SideMenuRoutes.unshift({
                id: TypesMenuButton.NewCase,
                label: 'File a New Dispute',
                visible: false,
                routerLink: '/my-disputes/claimant-intake-form',
            });
        }

        // BACKUP FOR WHEN ROLE is LegalRepresentative & associatedTo its null
        if (!userState.associatedTo) {
            this.generalSettingsService.getUserAssociatedToLegalRepresentative();
        }
    }
}
